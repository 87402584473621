@import url('https://use.typekit.net/lpr1krm.css');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-style: normal;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

iframe.payslip {
  height: 100%;
  width: 100%;
}

/* Transitions */

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/* --------- Datepicker css ----------- */

.pinpoint-calendar {
  z-index: 100;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 50;
}

.react-datepicker__header,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #0344b9;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: #fff;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker__triangle::before {
  border-bottom-color: #0344b9;
}
